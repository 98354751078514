import React, { Component } from 'react';
//<img src="/images/logo-icon-01.png" width="30" height="30" className="d-inline-block align-top" alt="" />
export default class Header extends Component
{
    state = {
    }
    render()
    {
        return (
            <header>
                <div className="col-12 col-auto">
                    <h1 title="The Five Reflections"><a href="/">The Five Reflections</a></h1>
                    <div title="MB-YB-MM-YM-W" className="reflection-symbols">MB-YB-MM-YM-W</div>
                </div>                     
            </header>
        )
    }
}