import React, { Component } from 'react';

export default class Three extends Component
{
    state = {
    }
    render()
    {        
        return (
            <div className="my-container-fluid views-home views-three">
                <div className="reflections">
                    <img title="How do I treat myself? How do I treat you? How do I treat the world?" alt="How do I treat myself? How do I treat you? How do I treat the world?" className="reflections-standard" src="/images/reflections-three-large.png" />
                    <img title="How do I treat myself? How do I treat you? How do I treat the world?" alt="How do I treat myself? How do I treat you? How do I treat the world?" className="reflections-mobile" src="/images/reflections-three-small.png" />
                </div>
            </div>
        )
    }
}