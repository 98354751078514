import React, { Component } from 'react';

// var reflections = [
//     {
//         Id: 1,
//         Symbol: "MB",
//         Message: "How do I treat my body?"
//     },
//     {
//         Id: 2,
//         Symbol: "YB",
//         Message: "How do I treat your body?"
//     },
//     {
//         Id: 3,
//         Symbol: "MM",
//         Message: "How do I treat my mind?"
//     },
//     {
//         Id: 4,
//         Symbol: "YM",
//         Message: "How do I treat your mind?"
//     },
//     {
//         Id: 5,
//         Symbol: "W",
//         Message: "How do I treat the world?"
//     }
// ];


export default class Home extends Component
{    
    render()
    {
        // let reflection_items = [];
        // for (var reflection_index = 0; reflection_index < reflections.length; reflection_index++)
        // {
        //     reflection_items.push(
        //         <Reflection key={reflections[reflection_index].Id} model={reflections[reflection_index]} />
        //     );
        // }


        return (
            <div className="my-container-fluid views-home views-five">
                <div className="reflections">
                    <img title="How do I treat my body? How do I treat your body? How do I treat my mind? How do I treat your mind? How do I treat the world?" alt="How do I treat my body? How do I treat your body? How do I treat my mind? How do I treat your mind? How do I treat the world?" className="reflections-standard" src="/images/reflections-five-large.png" />
                    <img title="How do I treat my body? How do I treat your body? How do I treat my mind? How do I treat your mind? How do I treat the world?" alt="How do I treat my body? How do I treat your body? How do I treat my mind? How do I treat your mind? How do I treat the world?" className="reflections-mobile" src="/images/reflections-five-large.png" />
                </div>
            </div>
        )
    }
}