import React, { Component } from 'react';


export default class Footer extends Component
{
    state = {
    }
    render()
    {
        return (
            <footer>
                <div className="background"></div>
                <div className="sayings">
                    <h2 className="circle-of-concern" title="Grow your strength by growing your circle of concern.">Grow your strength by growing your circle of concern.</h2>
                    <h2 className="circle-of-concern" title="The Universe is neither fair nor unfair. It just is.">The Universe is neither fair nor unfair. It just is.</h2>
                    <h2 className="learn" title="Learn from everyone and everything.">Learn from everyone and everything.</h2>
                    <h2 className="learn" title="Worship no one.">Worship no one.</h2>
                    <h2 className="five-three-one" title="5-3-1">5-3-1</h2>
                    <h2>
                        <img title="the reflection tree" alt="the reflection tree" src="/images/the-tree.png" style={{ display: 'none' }} />
                    </h2>
                </div>
            </footer>
        )
    }
}