import React, { Component } from 'react';

export default class One extends Component
{
    state = {
    }
    render()
    {        
        return (
            <div className="my-container-fluid views-home views-one">
                <div className="reflections">
                    <img title="How do I treat the world?" alt="How do I treat the world?" className="reflections-standard" src="/images/reflections-one.png" />                    
                </div>
            </div>
        )
    }
}