import React from 'react';
import './App.scss';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Views/Home';
//import Reflections from './Views/Reflections';
import Five from './Views/Five';
import Three from './Views/Three';
import One from './Views/One';
import Header from './components/Header';
import Footer from './components/Footer';

function App()
{
    return (
        <div className="App">
            <Header />

            <BrowserRouter>
                <div>
                    <Switch>
                        <Route exact={true} path='/Five' render={() => (
                            <Five />
                        )} />
                        <Route exact={true} path='/Three' render={() => (
                            <Three />
                        )} />
                        <Route exact={true} path='/One' render={() => (
                            <One />
                        )} />
                        <Route path='*' render={() => (
                            <Home />
                        )} />
                    </Switch>
                </div>
            </BrowserRouter>

            <Footer />
        </div>
    );
}

export default App;
